.faq {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.faq-header {
  color: #fff;
  margin-top: 0;
  font-size: 45px;
  text-align: center;
  margin: 20px 0 40px 0;
}

.faq-text {
  color: #fff;
}

.faq-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}

.btn-faq {
  height: 3rem;
  width: 9rem;
  font-size: 1.1rem;
}

.contact-section {
  display: flex;
  margin-bottom: 20px;
}

.contact-form {
  width: 350px;
}

.form-section {
  display: flex;
}

.contact-form {
  width: 400px;
}

.contact-input {
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  background: #ffffff50;
  outline: none;
  border: 1px solid #eee;
  color: #fff;
  font-weight: bold;
}

.contact-text-input {
  width: 300px;
  min-height: 80px;
  padding: 10px;
  background: #ffffff50;
  outline: none;
  border: 1px solid #eee;
  color: #fff;
  font-weight: bold;
}

.contact-rules {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.contact-rules > span {
  color: white;
  margin-bottom: 10px;
  display: list-item;
}

.contact-rules > h3 {
  color: white;
  margin-bottom: 20px;
}

.contact-label {
  width: 80px;
  color: white;
  font-weight: bold;
  margin-top: 8px;
}

.form-section {
  margin-top: 30px;
}

.btn-submit {
  width: 10rem;
  background-color: var(--orange);
  color: white;
  float: right;
}

@media screen and (max-width: 768px) {
  .row-title-text {
    font-size: 18px;
  }
}
