.Phone {
  padding: 2rem 2rem 5rem 2rem;
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.phone-container {
  position: relative;
  height: 600px;
  width: 335px;
  overflow: hidden;
  z-index: 5;
}

.smartphone {
  height: 600px;
  width: 335px;
}

.display-img {
  position: absolute;
  top: 84px;
  left: 40px;
  height: 71.5%;
  width: 260px;
  overflow: hidden;
  background: #111;
}

.slide-img {
  height: 100%;
  width: 100%;
}

.slide-btn {
  display: flex;
  position: absolute;
  bottom: 45px;
  left: 60px;
}

.slide-btn > button {
  width: 5rem;

  height: 30px;
  margin: 0 1rem;
  border-radius: 5px;
  font-size: 1.6rem;
  padding-bottom: 10px;
  background-color: #11111150;
  opacity: 0.7;
  color: #fff;
}

.slide-btn > button:hover {
  opacity: 1;
  background-color: #333;
}

.p-right {
  z-index: 0;
  padding-right: 5rem;
}

.r-title > span {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: bold;
}

.title {
  color: #fff;
}

.main-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.list-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.list-container > img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.point-list {
  font-size: 1.1rem;
  color: #fff;
  margin-top: 3px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .Phone {
    flex-direction: column;
  }
}
