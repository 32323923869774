.terms {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.m-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

li {
  margin-bottom: 8px;
}

.h1 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
}

.h2 {
  font-size: 16px;
  margin: 20px 0 10px 0;
  color: #fff;
  font-weight: bold;
}

.p-main {
  text-align: justify;
  color: #fff;
  font-size: 14px;
}

.p-main2 {
  text-align: justify;
  font-size: 18px;
  margin: 30px 0 0 0px;
  color: #fff;
  font-weight: bold;
}
