.Footer-container {
  position: relative;
  margin-top: 50px;
  border-top: 1px solid #888;
}

.Footer-container > hr {
  margin-top: 80px;
  border: 0.5px solid var(--lightgray);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}

.social-links {
  display: flex;
  gap: 4rem;
  padding: 0 2rem;
}

.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin-top: 1rem;
}

.logo-f > img {
  width: 6rem;
}

.blur-f-1 {
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}

.blur-f-2 {
  bottom: 0;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}

.web-links {
  display: flex;
  align-items: center;
}

.web-links > a {
  padding: 1rem 1.5rem 0 1.5rem;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
}
