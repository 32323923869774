.hero {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0rem 5rem 0rem;
}

.left-h {
  padding: 0 2rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.right-h {
  flex: 1;
  position: relative;
  background-image: var(--planCard);
}

.the-best-ad {
  margin-top: 3rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.the-best-ad > span {
  z-index: 2;
  font-weight: bold;
}

.the-best-ad > div {
  position: absolute;
  background-color: var(--red);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
  padding-top: 1.5rem;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  gap: 2rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.figures > div > div {
  font-size: x-large;
  color: white;
  font-weight: bold;
}

.figures > div > span {
  text-transform: capitalize;
}

.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.span {
  color: #fff;
  font-weight: bold;
}

.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  cursor: pointer;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.hero-buttons > :nth-child(1) {
  color: white;
  background-color: var(--red);
  width: 8rem;
}

.hero-buttons > :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--red);
}

/* .right-h > .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
} */

.heart-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 4rem;
}

.heart-rate > img {
  width: 2rem;
}

.heart-rate > :nth-child(2) {
  color: var(--gray);
  font-weight: bold;
  font-size: 0.8rem;
}

.heart-rate > :nth-child(3) {
  color: white;
  font-size: 1.5rem;
  margin-top: -10px;
}

.hero-image {
  position: absolute;
  top: 2rem;
  right: 8rem;
  width: 23rem;
}

.hero-image-back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 20rem;
  right: 28rem;
  width: 6rem;
}

.calories > img {
  width: 3rem;
}

.calories > div {
  display: flex;
  margin-top: -20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.calories > div > :nth-child(1) {
  color: var(--gray);
}

.calories > div > :nth-child(1) {
  color: var(--gray);
  font-size: 0.8rem;
  font-weight: bold;
}

.calories > div > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
  margin-top: 5px;
}

.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.store-btn {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.link-text {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  font-size: 18px;
}

.link-text > span {
  color: white;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: bold;
}

.link-btn {
  height: 4rem;
  width: 14rem;
  padding: 0.5rem 1rem;
}
